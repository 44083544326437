import React from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { StaticImage } from "gatsby-plugin-image";

// Import layout
import Layout from "../layouts/main";

// Import components
import MetaData from "../components/MetaData";

// Import styles
import "./styles/post-content.scss";
import "./styles/post.scss";

// Import assets
import image from "../assets/images/og-image.jpg";

// Import bootstrap
import { Button, Col, Container, Row } from "react-bootstrap";

const Post = ({ data, pageContext }) => {
  const { wpService } = data;
  const { previous, next } = pageContext;
  return (
    <Layout>
      <MetaData
        title={wpService.seoFields.seo.title}
        description={wpService.seoFields.seo.description}
        image={`https://mountaineer-dental.netlify.app${image}`}
      />
      <div className="post-template">
        <div className="background-shape" />
        <Row className="intro fade-in-slow">
          <div className="thumbnail">
            {wpService.featuredImage ? (
              <GatsbyImage
                className="image"
                image={
                  wpService.featuredImage.node.localFile.childImageSharp
                    .gatsbyImageData
                }
                alt={wpService.featuredImage.node.altText}
              />
            ) : (
              <StaticImage
                className="image"
                src="../assets/images/placeholder.png"
                alt="The Adaptix logo."
                placeholder="tracedSVG"
              />
            )}
          </div>
          <div className="text">
            <h1 className="shout">{wpService.title}</h1>
          </div>
        </Row>
        <Container className="post-content-container fade-in-slow">
          <div
            className="post-content"
            dangerouslySetInnerHTML={{
              __html: wpService.serviceFields.content,
            }}
          />
          <div className="post-pagination">
            <ul>
              <li>
                {next && (
                  <Link to={`/services/${next.slug}`} rel="next">
                    <Button variant="accent">← Prev</Button>
                  </Link>
                )}
              </li>
              <li>
                {previous && (
                  <Link to={`/services/${previous.slug}`} rel="prev">
                    <Button variant="accent">Next →</Button>
                  </Link>
                )}
              </li>
            </ul>
          </div>
        </Container>
      </div>
    </Layout>
  );
};

export default Post;

export const query = graphql`
  query WpServiceById($slug: String!) {
    wpService(slug: { eq: $slug }) {
      __typename
      featuredImage {
        node {
          altText
          id
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG, formats: AUTO)
            }
          }
        }
      }
      serviceFields {
        content
      }
      seoFields {
        seo {
          title
          description
        }
      }
      title
    }
  }
`;
